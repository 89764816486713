<template>
  <div class="com">
    <marquee-text :paused="true" :duration="20">
      <a
        v-if="url"
        class="value"
        :href="url"
        target="_blank"
        rel="noopener noreferrer"
      >
        {{ value }}</a>
      <div v-else class="value"> <span>{{ time }}: </span>{{ value }}</div>
    </marquee-text>
  </div>
</template>

<script>
import MarqueeText from 'vue-marquee-text-component'
import moment from 'moment'

export default {
  name: 'Notify',
  components: {
    MarqueeText
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      time: moment().format('YYYY-MM-DD')
    }
  }
}
</script>

<style lang="scss" scoped>
.com {
  background-color: #646464;
  line-height: 42px;
  color: #ffffff;
  text-align: center;
  a {
    color: #ffffff;
    text-decoration: none;
  }

  .value {
    width: 100vw;
  }
}
</style>
