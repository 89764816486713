<template>
  <div v-if="headers.length" class="thumb">
    <el-carousel
      :type="device === 'desktop' ? 'card' : ''"
      :interval="6000"
      class="swiper"
      height="300px"
    >
      <el-carousel-item
        v-for="item in headers"
        :key="item._id"
        :style="{ backgroundImage: 'url(' + item.cover + ')' }"
        class="swiper-slide"
      >
        <div v-if="item.title" class="content-wrap" @click="handlerClick(item)">
          <div class="content">
            <h2 class="title">
              {{ item.title }}
            </h2>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'TopSwiper',
  components: {},
  props: {
    headers: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      handlerClick(item) {
        window.open(item.url)
      }
    }
  },
  computed: {
    ...mapGetters(['device'])
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.thumb {
  height: 300px;
}

.swiper {
  .swiper-slide {
    background-size: cover;
    background-position: center;
    cursor: pointer;
    .content-wrap {
      background-color: rgba($color: #000, $alpha: 0.3);
      height: 100%;
      width: 100%;
      display: flex;
      align-items:flex-end;
      justify-content: center;

      .content {
        width: 50vw;
        text-align: center;
        line-height: 1.5em;
        color: #ffffff;
        padding: 40px 40px 0;
        .digest {
          font-size: 13px;
          line-height: 24px;
        }
        .title {
          a {
            color: #ffffff;
            text-decoration: none;
          }
        }
      }
    }
  }

  &.gallery-top {
    height: 100%;
    width: 100%;
    opacity: 0.9;
  }
}
</style>
