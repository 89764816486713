<template>
  <div class="page">
    <notify v-if="notify.content" v-model="notify.content" :url="notify.url" />
    <div class="top-wrap">
      <top-logo :logo="logo" />
    </div>
    <div class="top-swipper">
      <top-swiper :headers="banner" />
    </div>
    <div class="body-wrap">
      <div class="articles body-item">

        <div class="categrays">
          <div
            v-for="item in categroys"
            :key="item"
            class="cate-gray-item"
            @click="handlerGoCateGroy(item)"
          >
            {{ item }}
          </div>
        </div>

        <div class="title">
          最新消息
        </div>
        <div
          v-for="(item, index) in articles"
          :key="item._id"
          :class="(index > 1 ? 'a-sm' : 'a-bg') + ' article'"
          @click="handlerGoDetail(item)"
        >
          <div class="image">
            <el-image fit="cover" :src="item.image_url">
              <div
                slot="error"
                class="image-slot"
                style="display:flex;width:100%;height:100%;justify-content: center;align-items: center;"
              >
                <i class="el-icon-picture-outline" />
              </div>
            </el-image>
          </div>
          <div class="content">
            <div class="content-title">
              {{ item.title }}
            </div>
            <div class="content-digest">
              {{ item.digest }}
            </div>
          </div>
        </div>
      </div>
      <div class="left body-item">
        <div class="categrays left-item">
          <div
            v-for="item in categroys"
            :key="item"
            class="cate-gray-item"
            @click="handlerGoCateGroy(item)"
          >
            {{ item }}
          </div>
        </div>
        <div class="features left-item">
          <div
            v-for="item in features"
            :key="item.cover"
            class="feature-item"
            @click="handlerGoFeature(item)"
          >
            <el-image :src="item.cover" />
          </div>
        </div>
        <div v-if="wx_qrcode" class="left-item wx_qrcode">
          <el-image :src="wx_qrcode" />
        </div>
        <div v-if="weiyuanhui" class="left-item wx_qrcode" @click="handlerGoWeiYuanHui">
          <el-image :src="weiyuanhui" />
        </div>
        <div
          v-if="donwload"
          class="left-item wx_qrcode"
          @click="handlerGoDonwload"
        >
          <el-image pre :src="donwload" />
        </div>

        <div class="left-item coops">
          <coops v-model="user_coops" />
          <coops v-model="company_coops" type="company" />
        </div>

        <div class="left-item coops">
          <coops v-model="map" type="map" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page {
  min-height: 100vh;
}

.top-swipper {
  margin: 10px 0;
}
.body-wrap {
  width: 960px;
  display: flex;
  margin: 0 auto;
  .body-item {
    flex: 1;
    width: 50%;
    margin: 0 10px;
  }

  .articles {
    background-color: #ffffff;
    border-radius: 25px;
    .title {
      font-weight: bold;
      line-height: 1.5em;
      font-size: 22px;
      padding: 10px 20px;
    }
    .article {
      margin: 10px;
      padding: 10px;
      cursor: pointer;
      border-bottom: #dcdfe6 1px solid;
      .content {
        .content-title {
          font-size: 20px;
          line-height: 1.5em;
        }
        .content-digest {
          font-size: 14px;
          line-height: 1.5em;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          word-break: break-all;
          word-wrap: break-word;
        }
      }
    }

    .a-bg {
      .image {
        height: 220px;
      }
      .el-image {
        height: 220px;
        width: 100%;
      }
    }
    .a-sm {
      display: flex;
      flex-direction: row-reverse;
      .el-image {
        height: 120px;
        width: 120px;
        padding: 0 10px;
      }
    }
    .el-divider--horizontal {
      margin: 10px 0;
    }
  }
  .left {
    .left-item {
      margin-bottom: 10px;
    }
    .features,
    .wx_qrcode {
      background-color: #ffffff;
      padding: 10px;
      border-radius: 10px;
      .feature-item {
        cursor: pointer;
        padding-top: 10px;
      }
    }
  }

  .categrays {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      .cate-gray-item {
        cursor: pointer;
        width: 40%;
        margin-top: 10px;
        border-radius: 20px;
        background-color: #5c8c72;
        box-shadow: 2px 2px 2px #b3b8b6;
        font-weight: bold;
        color: #ffffff;
        text-align: center;
        font-size: 28px;
        padding: 12px;
      }
      .cate-gray-item:hover {
        color: #409eff;
        box-shadow: 2px 2px 2px #929795;
      }
  }
}

@media screen and (min-width: 992px) {
  .articles {
    .categrays {
      display: none;
    }
  }
}

@media screen and (max-width: 992px) {
  .body-wrap {
    width: 100% !important;
    margin: 0 0 !important;
    .left {
      display: none;
    }
  }
}
</style>

<script>
import Notify from '@/components/Notify'
import { getIndexData } from '@/api/index'
import TopLogo from '@/components/TopLogo'
import TopSwiper from './component/topswiper.vue'
import Coops from './component/coops'
export default {
  name: 'HomePage',
  components: {
    Notify,
    TopLogo,
    TopSwiper,
    Coops
  },
  data() {
    return {
      notify: {
        content: '1312312',
        url: ''
      },
      logo: '',
      banner: [],
      articles: [],
      features: [],
      wx_qrcode: '',
      donwload: '',
      company_coops: '',
      weiyuanhui: '',
      user_coops: [],
      map: '',
      categroys: ['新闻动态', '营养与加工', '产业与政策', '联系我们']
    }
  },
  mounted() {
    this.handlerGetIndexData()
  },
  methods: {
    handlerGoWeiYuanHui() {
      window.open('https://mp.weixin.qq.com/s/DclqsgPBl-vKv5xDPHw7fQ')
    },
    handlerGetIndexData() {
      const loading = this.$loading({
        lock: true,
        text: '正在加载',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.9)'
      })
      getIndexData().then(res => {
        const home_data = res.home_page
        this.notify = {
          content: home_data.notify_content,
          url: home_data.notify_url
        }
        this.logo = home_data.logo
        this.banner = res.banner
        this.articles = res.articles
        if (home_data.feature1_cover) {
          this.features.push({
            cover: home_data.feature1_cover,
            url: home_data.feature1_url
          })
        }
        if (home_data.feature2_cover) {
          this.features.push({
            cover: home_data.feature2_cover,
            url: home_data.feature2_url
          })
        }
        this.wx_qrcode = home_data.wx_qrcode
        this.donwload = home_data.donwload
        this.weiyuanhui = home_data.weiyuanhui
        this.user_coops = res.user_coops
        this.company_coops = home_data.company_coops
        this.map = home_data.map
        setTimeout(() => {
          loading.close()
        }, 500)
      })
    },
    handlerGoDetail(row) {
      if (row.wx_url) {
        window.open(row.wx_url)
      } else {
        this.$router.push('/article/' + row._id)
      }
    },
    handlerGoCateGroy(item) {
      this.$router.push('/article/list/' + item)
    },
    handlerGoFeature(e) {
      window.open(e.url)
    },
    handlerGoDonwload() {
      this.$router.push('/article/file/list')
    }
  }
}
</script>
