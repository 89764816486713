<template>
  <div class="com coops">
    <div class="title">
      {{ title }}
    </div>
    <el-divider>{{ sub_title }}</el-divider>

    <template v-if="type === 'users'">
      <div
        v-for="item in value"
        :key="item.title"
        class="coop-item"
        @click="handlerOpen(item)"
      >
        <el-image v-if="item.image_url" fit="cover" :src="item.image_url" />
        <div v-if="item.title">{{ item.title }}</div>
      </div>
    </template>
    <div v-else class="">
      <el-image :preview-src-list="[value]" :src="value" fit="cover" />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.coops {
  background-color: #ffffff;
  border-radius: 10px;
  .title {
    font-size: 22px;
    text-align: center;
    font-weight: bold;
    padding: 10px 0;
    width: 100%;
  }
  .el-divider--horizontal {
    margin: 10px 0;
  }
  display: flex;
  flex-wrap: wrap;
  .coop-item {
    padding: 10px 20px;
    cursor: pointer;
  }

  .coop-item:hover {
    color: #409eff;
  }
}
</style>

<script>
export default {
  props: {
    value: {
      type: String,
      default: () => {
        return []
      }
    },
    type: {
      type: String,
      default: 'users'
    }
  },
  computed: {
    title() {
      if (this.type === 'users') {
        return '专家团队'
      } else if (this.type === 'company') {
        return '会员单位'
      } else {
        return '主产地'
      }
    },
    sub_title() {
      if (this.type === 'users') {
        return '杂粮产业工作委员会'
      }
      return '杂粮产业工作委员会'
    }
  },
  methods: {
    handlerOpen(item) {
      if (item._id) {
        window.open('/article/' + item._id)
      }
    }
  }
}
</script>
